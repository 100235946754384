@import "./variables.scss";
/* import bootstrap */
@import "~bootstrap/scss/bootstrap.scss";

/* Carousel */
@import "~bootstrap/scss/carousel";

/* NgSelect default theme */
@import "~@ng-select/ng-select/themes/default.theme.css";

/* ngx-toastr styles */
@import "~ngx-toastr/toastr-bs4-alert";

/*====================================
|||||||||||| Add fonts||||||||||||||||
=====================================*/
@font-face {
  font-family: "poppins-regular";
  src: url("assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "poppins-bold";
  src: url("assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "poppins-medium";
  src: url("assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "poppins-semiBold";
  src: url("assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "poppins-regular";
  background-color: $white;
  overflow-x: hidden;
  touch-action: pan-x pan-y;
}
html {
  overflow-x: hidden;
}

/* Heading */
h1,
h2,
h3,
h4 {
  color: $black;
}
h1 {
  font-family: "poppins-bold";
  font-weight: 700;
  @media (max-width: 568px) {
    font-size: 1.5rem;
  }
}
h2 {
  font-family: "poppins-medium";
  font-weight: 500;
}
h3 {
  font-family: "poppins-semiBold";
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
}
h4 {
  font-family: "poppins-medium";
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
}
a {
  cursor: pointer;
}

.fs-md-4 {
  @media (min-width: 568px) {
    font-size: 1.5rem;
  }
}
.fs-md-5 {
  @media (min-width: 568px) {
    font-size: 1.125rem;
  }
}
.fs-md-6 {
  @media (max-width: 568px) {
    font-size: 0.875rem;
  }
}
.fs-md-7 {
  @media (max-width: 568px) {
    font-size: 0.75rem;
  }
}

// Bootstrap Override
.px-md-7 {
  @media (min-width: 992px) {
    padding-left: 4rem !important;
    padding-right: 2rem !important;
  }
  @media (min-width: 1200px) {
    padding-left: 6rem !important;
    padding-right: 2rem !important;
  }
  @media (min-width: 1400px) {
    padding-left: 12rem !important;
    padding-right: 6rem !important;
  }
}
.pl-md-7 {
  @media (min-width: 992px) {
    padding-left: 4rem !important;
  }
  @media (min-width: 1200px) {
    padding-left: 6rem !important;
  }
  @media (min-width: 1400px) {
    padding-left: 12rem !important;
  }
}

.navbar-brand {
  img {
    width: 9rem;
    @media (max-width: 768px) {
      width: 7rem;
    }
  }
}

.btn-primary,
.btn-danger,
.btn-dark {
  padding: 0.75rem 1.875rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: all 0.5s linear;
  @media screen and (min-width: 991px) {
    padding: 1.25rem 3rem;
  }
}
.btn {
  outline: none;
  &:hover {
    box-shadow: none;
    border: none;
  }
}
.btn-primary {
  background-color: $light-white;
  &:hover {
    background-color: rgba($light-white, 0.3);
    box-shadow: none;
    border: none;
  }
}
.btn-danger {
  background-color: $light-red;
  font-size: 1rem;
  font-weight: 500;
  &:hover {
    background: rgba($light-red, 0.8);
    box-shadow: none;
    border: none;
  }
}
.btn-dark {
  background-color: black;
  &:hover {
    background-color: rgba($black, 0.8);
    box-shadow: none;
    border: none;
  }
}

/* overwrite ngx-bootstrap/tabs*/

.tab-container {
  .nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    &.nav-tabs {
      border-bottom-color: transparent;
      .nav-item {
        height: 15rem;
        width: 14rem;
        margin: 0 1rem;
        .nav-link {
          display: flex;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          border: 1px solid #dedce0;
          border-radius: 10px;
          padding: 1rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          p {
            font-family: "poppins-medium";
            color: $black;
            font-size: 1.25rem;
            line-height: 30px;
            margin: 1.5rem 0 0;
          }
          img {
            height: 4rem;
            width: 4rem;
            object-fit: contain;
          }
          &.active,
          &:hover {
            border-color: transparent;
            background-color: $black;
            p {
              color: $white;
            }
          }
        }
        @media (max-width: 760px) {
          height: 6.5rem;
          width: 5.8rem;
          margin: 0 0.5rem;
          .nav-link {
            p {
              font-size: 0.6rem;
              line-height: 1rem;
              margin: 0.5rem 0 0;
            }
            img {
              height: 2.5rem;
              width: 2.5rem;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }
}

/* overwrite bootstrap forms*/
.custom-select {
  font-size: 1rem;
  color: #000000;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: transparent;
  height: 54px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("assets/images/icons/down-arrow.svg") no-repeat;
  background-position: right 16px top 50%;
  &:hover,
  &:focus {
    border: 1px solid #000000;
    outline: 0 none;
    box-shadow: none;
  }
}

.form-group {
  margin-bottom: 1.5rem;
  .form-label {
    font-family: "poppins-medium";
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }
  .form-control {
    font-size: 1rem;
    color: #000000;
    padding: 24px 16px;
    background: #fff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 15px;
    &:hover,
    &:focus {
      border: 1px solid #000000;
      outline: 0 none;
      box-shadow: none;
    }
    &:disabled {
      background-color: #f3f3f3;
    }
    .btn {
      &:hover,
      &:focus,
      &:visited,
      &:active {
        border: none;
        outline: 0 none;
        box-shadow: none;
      }
    }
  }
}

// Carousel override
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
}

.slider {
  position: relative;
  padding: 2rem 0 4rem;
  .carousel-indicators {
    li {
      background-color: #dddddd;
      &.active {
        background-color: #c13f48;
      }
    }
  }
  .carousel {
    position: unset !important;
    height: 100% !important;
    .carousel-inner,
    .item,
    .carousel-item {
      height: 100% !important;
    }
  }
  @media (min-width: 768px) {
    padding: 4rem 0;
  }
}
// NgSelect Overrides
.ng-select.custom {
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        font-size: 0.8rem;
      }
      .ng-option-marked {
        background: $black;
        color: $white;
      }
    }
  }
  .ng-select-container {
    font-size: 0.8rem;
    padding: 24px 16px;
    box-shadow: none;
    border-radius: 15px;
    border: 1px solid #c4c4c4;
    .ng-value-container {
      .ng-input {
        left: 24px;
        top: 16px;
        width: auto;
      }
    }
    &:hover,
    &:focus {
      border: 1px solid #000000;
    }
    @media (max-width: 568px) {
      padding: 24px 10px;
    }
  }
  &.ng-select-searchable {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          left: 16px;
          top: 16px;
          width: auto;
          @media (max-width: 568px) {
            left: 10px;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: black;
  }
  .ng-clear-wrapper {
    display: none;
  }
  .ng-arrow-wrapper {
    .ng-arrow {
      border-style: hidden;
      background: url("assets/images/icons/down-arrow.svg") no-repeat;
      height: 20px;
      top: 7px;
      width: 24px;
    }
  }
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #000000;
  box-shadow: none;
}

// radio button
.form-input-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .form-check {
    width: 45%;
    margin-right: 0;
    input[type="radio"] {
      display: none;
      &:not(:disabled) ~ label {
        cursor: pointer;
      }
    }
    label {
      width: 100%;
      display: block;
      background: white;
      border: 1px solid #000000;
      border-radius: 20px;
      padding: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      position: relative;
      @media (max-width: 991px) {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
      }
    }
    input[type="radio"]:checked + label {
      background: #000000;
      color: #ffffff;
    }
    @media (max-width: 991px) {
      margin: 0.5rem 0;
    }
  }
}

// Form Validation
.submitted {
  .form-control.ng-invalid,
  .form-select.ng-invalid,
  .ng-invalid .from-control {
    border: 1px solid $light-red;
    border-radius: 15px;
  }
  .ng-invalid {
    .form-control {
      border: 1px solid $light-red;
    }
    &.ng-select {
      .ng-select-container {
        border-color: $light-red;
      }
    }
  }
  .form-check-input.ng-invalid + .form-check-label {
    color: $light-red;
  }
}

/* images */
.img-thumbnail {
  border: none;
  margin-bottom: 1rem;
  border-radius: 10px;
  object-fit: contain;
}
.rounded {
  object-fit: contain;
}
.embed-responsive {
  border-radius: 20px;
  margin: 2.5rem 0 3.5rem;
}

// Additional Utilities

.font-weight-semi-bold {
  font-family: "poppins-semiBold";
  font-weight: 600;
}

.font-color-white {
  color: #ffffff;
}
.font-color-gray {
  color: #5a5a5a;
}
.font-color-red {
  color: #c13f48;
}
.font-color-gradient {
  background: linear-gradient(
    90deg,
    #704b81 -0.01%,
    #a34071 34.9%,
    #c13f48 70.33%,
    #f3925a 100.04%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 2rem;
}
.no-scroll {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.no-padding-x {
  padding-left: 0;
  padding-right: 0;
}

.vh-lg-100 {
  @media only screen and (min-width: 768px) {
    height: 100vh !important;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.bg-black {
  background-color: #000000;
}

// Loader

.loader {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  z-index: 999;
  .circle {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid $light-red;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

// Combo Date picker
.submitted {
  .ng-invalid {
    .custom-select {
      border: 1px red solid;
    }
  }
}

// hide revoke cookie btn
.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(100px) !important;
}

/* Shadow */
.custom-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
/* circle image */
.circle-image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
  border: 0.8px solid #000000;
}

.black-triangle {
  &::before {
    content: "";
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 100px solid #000000;
    border-left: 100vw solid transparent;
    @media screen and (min-width: 768px) {
      top: -270px;
      border-bottom: 270px solid #000000;
    }
  }
}
